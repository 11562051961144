import {ReactElement} from 'react';
import {
  Item, ItemDefinitions,
} from './types';

export const shouldRenderItem = <ItemTypes, T extends keyof ItemTypes = keyof ItemTypes, Context extends unknown = undefined>(
  item: Item<ItemTypes, T>,
  definitions: ItemDefinitions<ItemTypes, Context, T>,
  context: Context,
): boolean => {
  const itemDefition = definitions[item.type as T];

  if (itemDefition.component) {
    const {shouldRenderComponent} = itemDefition;

    if (shouldRenderComponent && !shouldRenderComponent(item, context)) {
      return false;
    }

    return true;
  }
  
  return true;
};

export const renderItem = <ItemTypes, T extends keyof ItemTypes = keyof ItemTypes, Context extends unknown = undefined>(
  item: Item<ItemTypes, T>,
  definitions: ItemDefinitions<ItemTypes, Context, T>,
  context: Context,
): ReactElement | null => {
  const itemDefition = definitions[item.type as T];

  if (itemDefition.component) {
    const {
      component: Component, shouldRenderComponent,
    } = itemDefition;

    if (shouldRenderComponent && !shouldRenderComponent(item, context)) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <Component {...item as any} />;
  }

  return itemDefition.render(item, context);
};