import {css} from '@emotion/react';
import {
  faChevronDown, faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Block,
  Dropdown, renderItem, useRequiredContext,
} from '@snapper/core';
import {
  FC, useCallback, useRef,
} from 'react';
import {
  menuDefinitions,
  MenuItem, TopMenuItem,
} from '../definitions/menu-items';
import {Context} from '../menu-context';

export const DropdownMenu: FC<MenuItem<'menu'>> = ({
  index,
  label,
  items,
}) => {
  const context = useRequiredContext(Context);

  const {
    closeMenu,
    activeMenu,
    setActiveMenu,
    isMobile,
  } = context;

  const onClick = useCallback(() => setActiveMenu(active => active?.items === items ? null : {
    index,
    items,
    title: label,
  }), [index, items, label, setActiveMenu]);

  const ref = useRef(null);

  return isMobile ? (
    <div
      className="menu-item"
      onClick={onClick}
    >
      {label}
      <div className="more">
        <FontAwesomeIcon
          icon={faChevronRight}
        />
      </div>

    </div>
  ) : (
    <>
      <TopMenuItem
        ref={ref}
        padding="8px 10px"
        borderRadius="5px"
        color="#fff"
        nowrap
        active={activeMenu?.items === items}
        onClick={onClick}
      >
        {label}
        <FontAwesomeIcon
          css={css`
          margin-left: 0.8em;
        `}
          icon={faChevronDown}
        />
      </TopMenuItem>
      {activeMenu?.index === index && (
        <Dropdown
          onClose={closeMenu}
          parentRef={ref}
          alignBottom
          distance={12}
          grow
          shadow
          base
        >
          <Block
            background="#fff"
            border
            borderTop={false}
            padding="40px"
            column
            width="100vw"
            maxWidth="1200px"
            marginBetween="20px"
            shadow
          >
            {activeMenu?.items.map(item => renderItem(item, menuDefinitions, context))}
          </Block>
        </Dropdown>
      )}
    </>
  );
};
