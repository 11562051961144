import {
  ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBars,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faDoorOpen,
  faThumbsUp,
  faTimes,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import {css} from '@emotion/react';
import {
  Dropdown,
  Block,
  renderItem,
  toggle,
} from '@snapper/core';
import {AnimatePresence} from 'framer-motion';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {
  MenuProps, ActiveMenu, MenuContext, Attach,
} from './types/menu';
import {
  MobileWrapper, SearchInput,
} from './styles/input-styles';
import {useSearch} from './hooks/use-search';
import {
  menuDefinitions, MenuItem,
} from './definitions/menu-items';
import {Context} from './menu-context';

library.add(
  fal,
  faThumbsUp,
  faUser,
  faDoorOpen,
  faChevronUp,
  faChevronRight,
  faChevronDown,
  faChevronLeft,
);

const hasAttach = (item: MenuItem, attach: Attach): item is MenuItem & ({attach: Attach[]} | {attach?: never}) => !('attach' in item) || item.attach?.includes(attach) || false;

export const Menu = ({
  className,
  history,
  data: {
    logo,
    isMobile = false,
    config: {
      primaryColor = '#7A3BCD',
      offset = 200,
      width,
    } = {},
    search,
    items,
    profile,
    buttons,
  },
}: MenuProps): ReactElement => {
  const [activeMenu, setActiveMenu] = useState<ActiveMenu | null>(null);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const ref = useRef<HTMLDivElement>(null);

  const closeMenu = useCallback(() => {
    setShowSearchResults(false);
    setActiveMenu(null);
    setShowProfileMenu(false);
    setShowMobileMenu(false);
  }, []);

  const context = useMemo<MenuContext>(() => ({
    history,
    isMobile,
    activeMenu,
    closeMenu,
    setActiveMenu,
    setShowProfileMenu,
    setShowMobileMenu,
  }), [activeMenu, closeMenu, history, isMobile]);

  const {
    search: doSearch,
    searchResult,
  } = useSearch(search, context);

  useEffect(() => {
    if (searchResult) {
      setShowSearchResults(true);
    }
  }, [searchResult]);

  useEffect(() => {
    if (showSearchResults) {
      setActiveMenu(null);
      setShowProfileMenu(false);
    }
  }, [showSearchResults]);

  useEffect(() => {
    if (activeMenu) {
      setShowSearchResults(false);
      setShowProfileMenu(false);
    }
  }, [activeMenu]);

  const renderItems = useCallback((items: MenuItem[] | undefined): (ReactElement | null)[] | null => items?.map((item, index) => renderItem(
    {
      ...item,
      index,
    } as MenuItem,
    menuDefinitions,
    context,
  )) || null, [context]);

  const menuItems = useMemo(() => renderItems(items?.filter(item => hasAttach(item, isMobile ? 'mobile' : 'desktop'))), [isMobile, items, renderItems]);
  const menuButtons = useMemo(() => renderItems(buttons?.filter(item => hasAttach(item, isMobile ? 'mobile' : 'desktop'))), [isMobile, buttons, renderItems]);

  const menuContent = useMemo<ReactNode>(() => {
    if (showSearchResults && searchResult && searchResult?.result) {
      // Show content from search result directly if 'result' key is non-empty
      return searchResult.result || null;
    }

    if (!showSearchResults && isMobile && showMobileMenu) {
      // If mobile menu is opened, show the regular menu items/buttons
      return (
        <>
          <div
            className="close"
            onClick={() => {
              closeMenu();
            }
            }
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="content">
            {activeMenu || showProfileMenu
              ? (
                <Block
                  className="parent"
                  row
                  style={{paddingLeft: 20}}
                  onClick={() => {
                    setActiveMenu(null);
                    setShowProfileMenu(false);
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  {showProfileMenu ? profile?.displayName : activeMenu?.title}
                </Block>
              )
              : null}
            {activeMenu || showProfileMenu
              ? renderItems((showProfileMenu ? profile?.items : activeMenu?.items)?.filter(item => hasAttach(item, 'mobile')))
              : (
                <>
                  {profile && profile.displayName && (
                    <div
                      className="menu-item profile"
                      onClick={() => {
                        if (!profile.items) {
                          return;
                        }
                        setShowProfileMenu(true);
                      }}
                    >
                      {profile.displayName}
                      {!!profile.items && (
                        <div className="more">
                          <FontAwesomeIcon
                            icon={['fal', 'chevron-right']}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {menuItems}
                  {menuButtons}
                </>
              )}
          </div>
        </>
      );
    }

    // Show profile menu or search result items
    const showItems = showProfileMenu && profile?.items || showSearchResults && searchResult?.items;

    const attachType = isMobile ? 'mobile' : 'desktop';

    return showItems ? renderItems(showItems.filter(item => hasAttach(item, attachType))) : null;
  }, [activeMenu, closeMenu, isMobile, menuButtons, menuItems, profile, renderItems, searchResult, showMobileMenu, showProfileMenu, showSearchResults]);

  useEffect(() => {
    const onKeyDown = ({key}: KeyboardEvent): void => {
      if (key === 'Escape') {
        closeMenu();
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [closeMenu]);

  return (
    <Context.Provider value={context}>
      <Block
        ref={ref}
        background={primaryColor}
        className={className}
        row
        style={{justifyContent: 'space-between'}}
        height="60px"
        padding="0 20px"
        justify="left"
        overflow="hidden"
      >
        {isMobile ? (
          <>
            <Block
              grow
              justify="left"
            >
              {logo?.src && (logo.href ? (
                <a href={logo.href}>
                  <img
                    className="logo"
                    src={logo.src}
                    height={logo.height}
                    alt={logo.alt}
                    title={logo.title}
                  />
                </a>
              ) : (
                <img
                  src={logo.src}
                  height="auto"
                  alt={logo.alt}
                  title={logo.title}
                />
              ))}
            </Block>
            <Block
              className="menu-ico"
              justify="right"
              css={{cursor: 'pointer'}}
              onClick={() => {
                setShowMobileMenu(toggle);
              }}
            >
              <FontAwesomeIcon
                icon={showMobileMenu ? faTimes : faBars}
                style={{fontSize: 30}}
                color="white"
              />
            </Block>
          </>
        ) : (
          <Block
            row
            grow
            shrink
            maxWidth={width}
          >
            <Block
              grow
              shrink
              justify="left"
              maxWidth={offset}
            >
              {logo?.src && (logo.href ? (
                <a href={logo.href}>
                  <img
                    className="logo"
                    src={logo.src}
                    height={logo.height}
                    alt={logo.alt}
                    title={logo.title}
                  />
                </a>
              ) : (
                <img
                  src={logo.src}
                  height={logo.height}
                  alt={logo.alt}
                  title={logo.title}
                />
              ))}
            </Block>
            <Block
              row
              justify="left"
              marginLeft="20px"
              marginBetween="20px"
            >
              {menuItems}
            </Block>
            <Block
              row
              grow
              shrink
              justify="right"
              marginLeft="20px"
              marginBetween="20px"
              color="#fff"
            >
              {search && (
                <SearchInput
                  type="text"
                  placeholder={search.placeholder}
                  onClick={() => {
                    if (searchResult) {
                      setShowSearchResults(true);
                    } else {
                      doSearch(searchTerm);
                    }
                    setActiveMenu(null);
                    setShowProfileMenu(false);
                  }}
                  value={searchTerm}
                  onChange={({target: {value}}) => {
                    setSearchTerm(value || '');
                    doSearch(value || '');
                  }}
                />
              )}
              {menuButtons}
              {profile && profile.displayName && (
                <Block
                  nowrap
                  padding="8px 12px"
                  background={showProfileMenu ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.2)'}
                  borderRadius="999px"
                  className="profile-container"
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setShowProfileMenu(toggle);
                    setShowSearchResults(false);
                    setActiveMenu(null);
                  }}
                >
                  {profile.items ? (
                    <FontAwesomeIcon
                      css={css`
                        margin-right: 0.8em;
                      `}
                      icon={(showProfileMenu ? faChevronUp : faChevronDown)}
                    />
                  ) : null}
                  {profile.displayName}
                </Block>
              )}
            </Block>
          </Block>
        )}
      </Block>
      {isMobile ? (
        <AnimatePresence>
          {menuContent
          && (
            <MobileWrapper
              key="mobile-menu"
            >
              {menuContent}
            </MobileWrapper>
          )}
        </AnimatePresence>
      ) : menuContent && (
        <Dropdown
          onClose={closeMenu}
          parentRef={ref}
          alignBottom
          alignRight={!isMobile}
          grow
          shadow
          shrink
          base
        >
          <Block
            background="#fff"
            border
            borderTop={false}
            padding="40px"
            column
            width="100vw"
            maxWidth={!isMobile ? showProfileMenu ? '600px' : '800px' : undefined}
            marginBetween="20px"
            shadow
          >
            {menuContent}
          </Block>
        </Dropdown>
      )}
    </Context.Provider>
  );
};
