import {
  useState, FC, ReactNode, RefObject, useLayoutEffect,
} from 'react';
import {css} from '@emotion/react';
import {Dropdown} from './Dropdown';

export interface TooltipProps {
  className?: string;
  children: ReactNode;
  parentRef: RefObject<HTMLElement>;
  onHover?: boolean;
  distance?: number;
  alignTop?: boolean;
  alignBottom?: boolean;
  alignRight?: boolean;
  alignCenter?: boolean;
  positionRight?: boolean;
  positionLeft?: boolean;
}

export const Tooltip: FC<TooltipProps> = ({
  className,
  children,
  parentRef,
  onHover,
  distance = 0,
  alignTop,
  alignBottom,
  alignRight,
  alignCenter,
  positionRight,
  positionLeft,
}) => {
  const [visible, setVisible] = useState(!onHover);
  const element = parentRef.current;

  useLayoutEffect(() => {
    if (!onHover || !element) {
      return undefined;
    }

    const onMouseEnter = (): void => {
      setVisible(true);
    };
    const onMouseLeave = (): void => {
      setVisible(false);
    };

    element.addEventListener('mouseenter', onMouseEnter);
    element.addEventListener('mouseleave', onMouseLeave);

    return () => {
      element.removeEventListener('mouseenter', onMouseEnter);
      element.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [parentRef, element, onHover]);

  return (
    visible && (
      <Dropdown
        className={className}
        parentRef={parentRef}
        alignTop={alignTop}
        alignCenter={alignCenter}
        alignRight={alignRight}
        positionRight={positionRight}
        positionLeft={positionLeft}
        alignBottom={alignBottom}
        distance={distance}
        grow
        shrink
        css={css`
          pointer-events: none;
        `}
      >
        {children}
      </Dropdown>
    ) || null
  );
};
