import {forwardRef} from 'react';
import styled from '@emotion/styled';
import {
  css, keyframes,
} from '@emotion/react';
import {
  Block, BlockProps,
} from './Layout';

const Bounce = keyframes`
  from {
    transform: translateY(0px);
  }
  30% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(0px);
  }
  to {
    transform: translateY(0px);
  }
`;

const Dot = styled.div<{
  duration: string;
  delay: string;
}>`
  flex: 0 0 auto;
  display: block;
  width: 0.5em;
  height: 0.5em;
  overflow: visible;
  border-radius: 50%;
  position: relative;
  margin: auto 0.25em;

  ${({
  duration, delay,
}) => css`
    animation: ${Bounce} ${duration} ease-out infinite;
    animation-delay: ${delay};
  `}
`;

export interface LoadingIndicatorProps extends BlockProps {
  color: BlockProps['color'];
}

export const LoadingIndicator = forwardRef<HTMLDivElement, LoadingIndicatorProps>(({
  color, ...props
}, ref) => (
  <Block
    ref={ref}
    row
    justify="center"
    overflow="visible"
    height="1em"
    margin="auto"
    css={css`
        ${Dot} {
          background-color: ${color};
        }
      `}
    {...props}
  >
    <Dot
      duration="1200ms"
      delay="300ms"
    />
    <Dot
      duration="1200ms"
      delay="500ms"
    />
    <Dot
      duration="1200ms"
      delay="700ms"
    />
  </Block>
));
