import {
  useEffect, ReactNode, useState, ReactElement,
} from 'react';
import {css} from '@emotion/react';

interface Props {
  children: ReactNode;
}

export const TabOutlineController = ({children}: Props): ReactElement => {
  const [outlineActive, setOutlineActive] = useState<boolean>(true);

  useEffect(() => {
    const onKeyDown = ({key}: KeyboardEvent): void => {
      if (key === 'Tab') {
        setOutlineActive(true);
      }
    };
    const onMouseDown = (): void => {
      setOutlineActive(false);
    };

    document.addEventListener('keydown', onKeyDown, true);
    document.addEventListener('mousedown', onMouseDown, true);

    return () => {
      document.removeEventListener('keydown', onKeyDown, true);
      document.removeEventListener('mousedown', onMouseDown, true);
    };
  }, []);

  return (
    <span
      css={css(!outlineActive
          && css`
            *:focus {
              outline: 0;
            }
            *::-moz-focus-inner {
              border: 0;
            }
          `)}
    >
      {children}
    </span>
  );
};
